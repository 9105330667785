<template>
  <RsModal
    class="modal-vehicle-change__wrapper"
    :title="$t('summaryPage.vehicleChangeModal.title')"
    @dismiss="closeModal"
  >
    <div class="max-h-[640px] flex flex-col space-y-4">
      <template v-if="status === VehicleChangeStatus.UNCHANGED || !status">
        <div class="flex justify-center">
          <img src="/img/looking-vehicle-change.svg">
        </div>
        <p class="font-semibold text-xl">{{ $t('summaryPage.vehicleChangeModal.lookingForVehicleTitle') }}</p>
        <p>{{ $t('summaryPage.vehicleChangeModal.lookingForVehicleMessage') }}</p>
        <div class="!mt-8">
          <RsButton @click="checkChangeStatus">{{ $t('summaryPage.vehicleChangeModal.refreshButton') }}</RsButton>
        </div>
      </template>
      <template v-else-if="status === VehicleChangeStatus.CAR_CHANGE">
        <div class="flex justify-center">
          <img src="/img/vehicle-changed.svg">
        </div>
        <p class="font-semibold text-xl">{{ $t('summaryPage.vehicleChangeModal.similarVehicleFoundTitle') }}</p>
        <p v-html="$t('summaryPage.vehicleChangeModal.similarVehicleFoundMessage')" />
        <RsLink class="!mt-8" :to="carChangeUrl">
          <RsButton>{{ $t('summaryPage.vehicleChangeModal.startFinalChecksButton') }}</RsButton>
        </RsLink>
      </template>
      <template v-else-if="status === VehicleChangeStatus.MODEL_CHANGE">
        <div class="flex justify-center">
          <img src="/img/vehicle-changed.svg">
        </div>
        <p class="font-semibold text-xl">{{ $t('summaryPage.vehicleChangeModal.differentVehicleFoundTitle') }}</p>
        <p v-html="$t('summaryPage.vehicleChangeModal.differentVehicleFoundMessage')" />
        <RsLink class="!mt-8" :to="modelChangeUrl">
          <RsButton>{{ $t('summaryPage.vehicleChangeModal.startCamperTutorialButton') }}</RsButton>
        </RsLink>
      </template>
    </div>

    <template #header>
      <div class="flex justify-between items-end w-full md:px-4">
        <RsHeadline variant="h2">
          {{ $t('summaryPage.vehicleChangeModal.title') }}
        </RsHeadline>
        <IcCloseBlack class="cursor-pointer" width="24" @click="closeModal"/>
      </div>
    </template>
  </RsModal>
</template>

<script lang="ts" setup>
import { type Booking } from '~/services/entities'
import useBookingRepository from '~/composables/repositories/useBookingRepository'
import { IcCloseBlack } from '~/components/common/icons'
import useLoading from '~/composables/useLoading'

const props = defineProps<{
  booking: Booking,
}>()
const { startLoading, stopLoading } = useLoading()
const { booking } = toRefs(props)

enum VehicleChangeStatus {
  UNCHANGED = 'UNCHANGED',
  CAR_CHANGE = 'CAR_CHANGE',
  MODEL_CHANGE = 'MODEL_CHANGE',
}

const status = ref<VehicleChangeStatus | null>()
const slug = ref<string>('')
const emit = defineEmits(['commit'])
const closeModal = () => emit('commit')

const { checkVehicleChange } = useBookingRepository()
onMounted(() => {
  checkChangeStatus()
})

const localePath = useLocalePath()
const route = useRoute()
const modelChangeUrl = computed(() => {
  return `${window.location.origin}${localePath({
    path: `/${slug.value}/onboarding`,
    query: {
      token: route.query.token,
      page: 1,
    },
    force: true
  })}`
})

const carChangeUrl = computed(() => {
  return `${window.location.origin}${localePath({
    path: `/${slug.value}/onboarding`,
    query: {
      token: route.query.token,
      page: 'CERTIFICATE',
      start_checking: 'true',
    },
    force: true
  })}`
})

const checkChangeStatus = async () => {
  try {
    startLoading()
    const response = await checkVehicleChange(booking.value?.bookingNumber, booking.value?.car?.id)
    status.value = response.data.status
    slug.value = response.data.onboarding_slug
  } catch {} finally {
    stopLoading()
  }
}
</script>

<style lang="postcss" scoped>
.modal-vehicle-change__wrapper :deep(.modal__window) {
  @apply max-h-[640px] rounded-lg h-auto;
}
.modal-vehicle-change__wrapper :deep(.modal__body) {
  @apply max-h-full;
}
.modal-vehicle-change__wrapper :deep(.modal__header) {
  @apply border-b-0;
}
.modal-vehicle-change__wrapper :deep(.modal__footer) {
  @apply border-t-0;
}
.modal-vehicle-change__wrapper {
  @apply !p-4 items-center;
}
</style>